<template>

  <!--
    @NOTE: this is a special route used to reload the patch create route since
    there's no easy way to reset a vue component (hey it's better than reloading
    the whole page!)
  -->

  <div />

</template>

<script>

  export default {
    created() {
      const params = Object.assign({}, this.$route.params)
      delete params.destinationRouteName
      this.$router.replace({
        name: this.$route.params.destinationRouteName,
        params,
      })
    },
  }

</script>

<style lang="stylus" scoped>

</style>
